<template>
  <div>
    <TopHeader></TopHeader>
    <b-container class="p-4">
      <b-row>
        <b-col sm="12" md="4" offset-md="4">
          <LoginForm></LoginForm>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LoginForm from '@/components/Login.vue';
import TopHeader from '@/components/Layout/TopHeader.vue';

export default {
  name: 'Login',
  components: {
    LoginForm,
    TopHeader,
  },
  data() {
    return {};
  },
  computed: {
    loggedIn() {
      return this.$acl.check('view');
    },
  },
  methods: {},
};
</script>
