<template>
  <b-container class="mt-4">
    <b-row>
      <b-col>
        <h4>Login</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p>
          The system you are accessing contains both Personal Health Information (PHI) and Personal Identifiable Information (PII).
          Please ensure you handle this data with the utmost care and in accordance with applicable regulations and Sword’s internal policies.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <form @submit.prevent="loginSubmit">
          <div class="row">
            <div class="col-12 form-group">
              <label>Environment</label>
              <select v-model="environment" class="form-control" @change="getParentEnvironment" required>
                <option selected>Open this select menu</option>
                <optgroup v-for="(envs,stability) in availableAPIs" :key="stability" :label="stability">
                  <option v-for="(url,name) in envs" :key="name" :value="name">{{ name }}</option>
                </optgroup>
              </select>
            </div>
          </div>
          <div v-if="!isAclOnlyEnabled" class="row">
            <div class="col-12 form-group">
              <label>Username</label>
              <input type="text"
                     v-model="username"
                     class="form-control"
                     placeholder="Enter username"
                     required>
            </div>
          </div>
          <div v-if="!isAclOnlyEnabled" class="row">
            <div class="col-12 form-group">
              <label>Password</label>
              <input type="password"
                     v-model="password"
                     class="form-control"
                     placeholder="Password"
                     required>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center">
              <b-button variant="primary"
                        type="submit"
                        :disabled="loggingIn">
                <b-spinner small v-if="loggingIn"></b-spinner>
                <span v-else>Login</span>
              </b-button>
            </div>
          </div>
        </form>
      </b-col>
    </b-row>
    <b-row v-if="isAclOnlyEnabled">
      <b-col>
        <div class="row">
          <div class="col-12 d-flex flex-column justify-content-center">
            <p>
              You are currently logged in using <strong>{{ aclUser?.email }}</strong> ACL account.
              <br>Click <a href="#" v-on:click="aclLogout">here</a> to use a different ACL account.
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import aclModule from '@/scripts/acl-module';

let parentEnvironment = '';

export default {
  data() {
    return {
      username: '',
      password: '',
      environment: '',
    };
  },
  computed: {
    ...mapState('Auth', [ 'loggingIn' ]),
    ...mapGetters({
      isAclOnlyEnabled: 'Core/isAclOnlyEnabled',
      aclUser: 'User/getAclUser',
    }),
    availableAPIs() {
      return this.$store.getters['Core/getAvailableApis'];
    },
  },
  methods: {
    ...mapActions('Auth', [ 'doLogin', 'selectEnvironment' ]),
    getParentEnvironment(event) {
      const index = event.target.selectedIndex;
      const option = event.target.options[index];
      const optgroup = option.parentElement;
      parentEnvironment = optgroup.getAttribute('label');
      this.selectEnvironment({ parentName: parentEnvironment, name: this.environment });
    },
    async loginSubmit() {
      if (await this.doLogin({
        username: this.username,
        password: this.password,
        environment: { parentName: parentEnvironment, name: this.environment },
      })) this.$router.replace({ name: 'Home' });
    },
    aclLogout() {
      this.$store.dispatch('User/deleteUserAuth', null, { root: true });
      this.$store.dispatch('Auth/logout', { root: true });
      aclModule.logout();
    },
  },
  async mounted() {
    const options = this.availableAPIs;
    const keys = Object.keys(options);
    if (keys.length === 1) {
      const subKeys = Object.keys(options[keys[0]]);
      if (subKeys.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        this.environment = subKeys[0];
        // eslint-disable-next-line prefer-destructuring
        parentEnvironment = keys[0];
        this.selectEnvironment({ parentName: parentEnvironment, name: this.environment });
      }
    }

    try {
      await aclModule.requestUserAccess();
    } catch (error) {
      if (this.isAclOnlyEnabled) {
        this.$noty.error('Error getting ACL Module token');
      }

      console.error(error);
    }
  },
};
</script>
